<template>
  <div>
    <div class="d-flex align-start">
      <h3>Price based rates</h3>
      <v-btn @click="showAdd" class="ml-auto" color="primary">Add rate</v-btn>
    </div>
    <div class="mt-5">
      <p v-if="!$parent.shippingZone.priceBasedShippingRate || $parent.shippingZone.priceBasedShippingRate.length == 0">
        Add rates based on the price of a customer's order.
      </p>
      <v-simple-table v-else>
        <template v-slot:default>
          <thead>
            <th class="text-left" style="padding-left: 16px;">Name</th>
            <th class="text-left" style="padding-left: 16px;">Range</th>
            <th class="text-left" style="padding-left: 16px;">Rate amount</th>
            <th class="text-left" style="padding-left: 16px;"></th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in $parent.shippingZone.priceBasedShippingRate" :key="item.name">
              <td>{{ item.name }}</td>
              <td>
                {{ item.minOrderSubtotal }} {{ currency.currencyCode() }} -
                {{ item.maxOrderSubtotal != 0 ? item.maxOrderSubtotal : 'and up' }}
                {{ item.maxOrderSubtotal != 0 ? currency.currencyCode() : '' }}
              </td>
              <td>
                <span v-if="parseFloat(item.price) > 0">{{ item.price }} {{ currency.currencyCode() }}</span>
                <span v-else>FREE</span>
              </td>
              <td class="text-right" style="padding: 0px;">
                <v-btn @click="showEdit(item, index)" class="btn-link">Edit</v-btn>
                <v-btn @click="del(index)" class="btn-link pr-0">Delete</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <modal title="Price based rates" model="modal" width="700" :callback="submit">
      <template slot="content">
        <v-row>
          <v-col cols="12">
            <label for="">Name</label>
            <v-text-field
              v-model="priceBasedRate.name"
              @input="$v.priceBasedRate.name.$touch"
              :error-messages="
                $v.priceBasedRate.name.$dirty && !$v.priceBasedRate.name.required ? $t('Name is required') : ''
              "
            ></v-text-field>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <h3>RANGE</h3>
            <v-row>
              <v-col cols="12" md="6">
                <label for="">Minimum order price</label>
                <v-text-field
                  type="number"
                  v-model="priceBasedRate.minOrderSubtotal"
                  :prefix="currency.currencyCode()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Maximum order price</label>
                <v-text-field
                  type="number"
                  v-model="priceBasedRate.maxOrderSubtotal"
                  :prefix="currency.currencyCode()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-0 pt-0">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <label for="">RATE</label>
                <v-checkbox v-model="priceBasedRate.freeShip" label="Free shipping rate"></v-checkbox>
                <v-col v-if="!priceBasedRate.freeShip" class="py-0 px-0" cols="6">
                  <label for="">Rate amount</label>
                  <v-text-field
                    type="number"
                    v-model="priceBasedRate.price"
                    :prefix="currency.currencyCode()"
                    @input="$v.priceBasedRate.price.$touch"
                    :error-messages="
                      $v.priceBasedRate.price.$dirty && !$v.priceBasedRate.price.required ? $t('Price is required') : ''
                    "
                  ></v-text-field>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </modal>
  </div>
</template>
<script>
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import currency from '@/helpers/currency';

export default {
  data() {
    return {
      modal: false,
      isEdit: false,
      itemIndex: '',
      priceBasedRate: {
        id: '',
        freeShip: false,
        maxOrderSubtotal: '',
        minOrderSubtotal: '',
        name: '',
        price: 0,
        priceUnit: localStorage.getItem('currencyCode') || 'USD',
      },
      currency,
    };
  },
  validations: {
    priceBasedRate: {
      name: {
        required,
      },
      price: {
        required,
      },
    },
  },
  methods: {
    showEdit(item, index) {
      this.isEdit = true;
      this.priceBasedRate = Object.assign({}, item);
      this.modal = true;
      this.itemIndex = index;
    },
    showAdd() {
      this.isEdit = false;
      this.priceBasedRate = {
        id: '',
        freeShip: false,
        maxOrderSubtotal: 0,
        minOrderSubtotal: 0,
        name: '',
        price: 0,
        priceUnit: localStorage.getItem('currencyCode') || 'USD',
      };
      this.modal = true;
    },
    del(index) {
      this.$parent.shippingZone.priceBasedShippingRate.splice(index, 1);
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.modal = false;
        if (!this.isEdit) {
          let isArray = Array.isArray(this.$parent.shippingZone.priceBasedShippingRate);
          if (!this.$parent.shippingZone.priceBasedShippingRate || !isArray) {
            this.$parent.shippingZone.priceBasedShippingRate = [];
          }
          this.$parent.shippingZone.priceBasedShippingRate.push(this.priceBasedRate);
        } else {
          this.$parent.shippingZone.priceBasedShippingRate.splice(this.itemIndex, 1, this.priceBasedRate);
        }
        this.changePrice();
      }
    },
    changePrice() {
      if (this.priceBasedRate.freeShip) {
        this.priceBasedRate.price = 0;
      }
    },
  },
  watch: {
    freeShip() {
      console.log(this.freeShip);
    },
  },
};
</script>
