<template>
  <div>
    <div class="d-flex align-center">
      <h3>Preferred rules</h3>
    </div>
    <p>Preferred rule to apply when order meet multiple rules</p>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="$parent.shippingZone.preferredRules"
          :items="items"
          item-text="name"
          item-value="value"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          name: 'Lowest returned rule',
          value: 'lowest',
        },
        {
          name: 'Highest returned rule',
          value: 'highest',
        },
      ],
    };
  },
};
</script>
