<template>
  <div class="shipping__countries">
    <div>
      <h3>Countries</h3>
    </div>
    <div class="mt-3">
      <v-autocomplete
        v-model="selected"
        @change="onChange"
        placeholder="Search countries"
        :search-input.sync="search"
        :items="countries"
        item-text="name"
        item-value="name"
        label=""
        multiple
        outlined
        dense
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item.name }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text caption"> (+{{ selected.length - 1 }} others) </span>
        </template>
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                No results matching " <strong>{{ search }}</strong> ".Press <kbd>enter</kbd> to create a new one
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
      <div class="list-countries">
        <div v-if="!$parent.shippingZone.countries || $parent.shippingZone.countries.length == 0">
          Rest of the world
        </div>
        <v-list v-else>
          <v-list-item-group>
            <v-list-item v-for="(item, i) in $parent.shippingZone.countries" :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon @click="removeCountry(i)">
                <v-icon small v-text="'fas fa-times'"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
  </div>
</template>
<script>
import { storeService } from '@/apis/store.s';
import { shippingService } from '@/apis/shipping';

export default {
  data() {
    return {
      search: null,
      selected: [],
      countries: [
        {
          name: 'Rest of the world',
        },
      ],
    };
  },
  created() {
    this.selected = this.$parent.shippingZone.countries;
    this.loadCountries();
  },
  methods: {
    removeCountry(i) {
      this.$parent.shippingZone.countries.splice(i, 1);
      this.selected.splice(i, 1);
      if (this.selected.length == 0) {
        this.$parent.shippingZone.type = 'rest_of_world';
      } else {
        this.$parent.shippingZone.type = 'specific';
      }
    },
    onChange(item) {
      this.selected.forEach(element => {
        let country = this.countries.find(c => c.name == element);
        if (country) {
          if (country.name == 'Rest of the world') {
            this.selected = [];
            this.$parent.shippingZone.countries = [];
          } else {
            if (this.$parent.shippingZone.countries) {
              if (!this.$parent.shippingZone.countries.find(c => c.name == country.name)) {
                this.$parent.shippingZone.countries.push(country);
              }
            } else {
              this.$parent.shippingZone.countries.push(country);
            }
          }
        }
      });
      if (this.selected.length == 0) {
        this.$parent.shippingZone.type = 'rest_of_world';
      } else {
        this.$parent.shippingZone.type = 'specific';
      }
    },
    async loadCountries() {
      try {
        let countriesData = await shippingService.getListCountries();
        this.countries = countriesData.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
