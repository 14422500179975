<template>
  <div>
    <div class="d-flex align-center">
      <h3>Item based rates</h3>
      <v-btn @click="showAdd" class="ml-auto" color="primary">Add rate</v-btn>
    </div>
    <div class="mt-5">
      <p v-if="!$parent.shippingZone.itemBasedShippingRate || $parent.shippingZone.itemBasedShippingRate.length == 0">
        Add a rate based on first item and each additional item.
      </p>
      <v-simple-table v-else>
        <template v-slot:default>
          <thead>
            <th class="text-left" style="padding-left: 16px;">Name</th>
            <th class="text-left" style="padding-left: 16px;">Apply for</th>
            <th class="text-left" style="padding-left: 16px;">First item</th>
            <th class="text-left" style="padding-left: 16px;">Each additional item</th>
            <th class="text-left" style="padding-left: 16px;"></th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in $parent.shippingZone.itemBasedShippingRate" :key="item.name">
              <td>
                <v-tooltip v-if="item.groupTag" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>far fa-info-circle</v-icon>
                  </template>
                  <span>Group: {{ item.groupTag }}</span>
                </v-tooltip>
                {{ item.name }}
              </td>
              <td>
                {{ item.rules.length > 0 ? 'Specific products' : 'All products' }}
                <v-tooltip v-if="item.rules.length > 0" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small>mdi-information</v-icon>
                  </template>
                  <span
                    >{{ convertFilterDataName(item.rules[0].column) }}
                    {{ convertFilterDataName(item.rules[0].relation) }} {{ item.rules[0].condition }}</span
                  >
                </v-tooltip>
              </td>
              <td>
                <span v-if="parseFloat(item.firstItemPrice) > 0"
                  >{{ item.firstItemPrice }} {{ currency.currencyCode() }}</span
                >
                <span v-else>FREE</span>
              </td>
              <td>
                <span v-if="parseFloat(item.additionalItemPrice) > 0">
                  {{ item.additionalItemPrice }} {{ currency.currencyCode() }}
                </span>
                <span v-else>FREE</span>
              </td>
              <td class="text-right pr-0">
                <v-btn class="btn-link" @click="showEdit(item)">Edit</v-btn>
                <v-btn class="btn-link pr-0" @click="del(index)">Delete</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <modal title="Items based rates" model="modal" width="750" :callback="submit">
      <template slot="content">
        <v-row>
          <v-col cols="12" class="form-component">
            <label for="">Name</label>
            <v-text-field
              v-model="itemBasedRate.name"
              placeholder="Standard Shipping"
              @input="$v.itemBasedRate.name.$touch"
              :error-messages="
                $v.itemBasedRate.name.$dirty && !$v.itemBasedRate.name.required ? $t('Name is required') : ''
              "
            ></v-text-field>
            <v-divider></v-divider>
          </v-col>
          <!-- <v-col cols="12" class="form-component">
            <label for="">GROUP</label>
            <div>Only one rule with highest price in a group of same tag will be displayed on checkout page.</div>
            <v-text-field v-model="itemBasedRate.groupTag" placeholder="Standard"></v-text-field>
            <v-divider></v-divider>
          </v-col> -->
          <v-col cols="12">
            <div class="d-flex align-center mb-3">
              <h3>FILTER</h3>
              <v-btn
                @click="addRule"
                :disabled="itemBasedRate.rules.length < 1 ? false : true"
                style="font-size: 12px"
                class="primary ml-auto"
                >+ ADD CONDITION</v-btn
              >
            </div>
            <p>Optional. If there's no filters, all products will be applied this rate.</p>
            <span v-if="this.itemBasedRate.rules.length > 0">Products must match:</span>
            <div class="d-flex justify-space-between" v-for="(rule, index) in itemBasedRate.rules" :key="index">
              <div style="width: 200px">
                <v-select
                  id="my-select"
                  dense
                  v-model="rule.column"
                  item-text="name"
                  item-value="value"
                  :items="filterTypes"
                ></v-select>
              </div>
              <div style="width: 250px">
                <v-select
                  dense
                  v-model="rule.relation"
                  item-text="name"
                  item-value="value"
                  :items="conditions"
                ></v-select>
              </div>
              <div style="width: 150px">
                <v-text-field v-model="rule.condition" placeholder="Enter keywords"></v-text-field>
              </div>
              <div class="mt-1">
                <v-btn
                  class=""
                  color="primary"
                  outlined
                  v-if="itemBasedRate.rules.length > 0"
                  @click="reduceFilter(index)"
                  ><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn
                >
              </div>
            </div>
            <v-divider></v-divider>
          </v-col>
          <!-- <v-col cols="12">
            <div class="d-flex align-center mb-3">
              <h3>EXCLUSION</h3>
              <v-btn @click="addExclude" class="ml-auto">+ ADD CONDITION</v-btn>
            </div>
            <p>Optional. If there's no conditions, no product will be excluded from this rule.</p>
            <v-row class="" v-for="(exclude, index) in itemBasedRate.excludeRules" :key="index">
              <v-col cols="3">
                <v-select v-model="exclude.column" item-text="name" item-value="value" :items="filterTypes"></v-select>
              </v-col>
              <v-col cols="4">
                <v-select v-model="exclude.relation" item-text="name" item-value="value" :items="conditions"></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="exclude.condition" placeholder="Enter keywords"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn class="mt-1"><v-icon small color="">fas fa-trash</v-icon></v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-col> -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <label for="">RATE</label>
                <v-checkbox
                  @change="setFreeShipping"
                  v-model="freeShipping"
                  hide-details
                  label="Free shipping rate"
                ></v-checkbox>
              </v-col>
              <v-col v-if="!freeShipping" cols="12" md="6">
                <label for="">First item</label>
                <v-text-field
                  type="number"
                  v-model="itemBasedRate.firstItemPrice"
                  :prefix="currency.currencyCode()"
                  @input="$v.itemBasedRate.firstItemPrice.$touch"
                  :error-messages="
                    $v.itemBasedRate.firstItemPrice.$dirty && !$v.itemBasedRate.firstItemPrice.required
                      ? $t('First item is required')
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-col v-if="!freeShipping" cols="12" md="6">
                <label for="">Each additional item</label>
                <v-text-field
                  type="number"
                  v-model="itemBasedRate.additionalItemPrice"
                  :prefix="currency.currencyCode()"
                  @input="$v.itemBasedRate.additionalItemPrice.$touch"
                  :error-messages="
                    $v.itemBasedRate.additionalItemPrice.$dirty && !$v.itemBasedRate.additionalItemPrice.required
                      ? $t('First item is required')
                      : ''
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </modal>
  </div>
</template>
<script>
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import convertName from '@/mixins/convert-data-name';
import currency from '@/helpers/currency';
export default {
  mixins: [convertName],
  data() {
    return {
      currency,
      modal: false,
      isEdit: false,
      freeShipping: false,
      itemBasedRate: {
        additionalItemPrice: null,
        excludeRules: [],
        firstItemPrice: null,
        groupTag: '',
        name: '',
        priceUnit: localStorage.getItem('currencyCode') || 'USD',
        rules: [],
      },
      filterTypes: [
        // {
        //   name: 'Product SKU',
        //   value: 'product_sku',
        // },
        {
          name: 'Product Type',
          value: 'product_type',
        },
        // {
        //   name: 'Product Tag',
        //   value: 'product_tag',
        // },
        // {
        //   name: 'Product Vendor',
        //   value: 'product_vendor',
        // },
        // {
        //   name: 'Collection',
        //   value: 'collection_title',
        // },
      ],
      conditions: [
        {
          name: 'contains',
          value: 'contains',
        },
        // {
        //   name: 'dose not contains',
        //   value: 'not_contains',
        // },
        // {
        //   name: 'starts with',
        //   value: 'starts_with',
        // },
        // {
        //   name: 'ends with',
        //   value: 'ends_with',
        // },
        {
          name: 'is equal to',
          value: 'equals',
        },
        // {
        //   name: 'is not equal to',
        //   value: 'not_equals',
        // },
        // {
        //   name: 'matches regex',
        //   value: 'matches_regex',
        // },
      ],
    };
  },
  validations: {
    itemBasedRate: {
      name: {
        required,
      },
      firstItemPrice: {
        required,
      },
      additionalItemPrice: {
        required,
      },
    },
  },
  methods: {
    reduceFilter(i) {
      this.itemBasedRate.rules.splice(i, 1);
    },
    setFreeShipping() {
      if (this.freeShipping) {
        this.itemBasedRate.firstItemPrice = 0;
        this.itemBasedRate.additionalItemPrice = 0;
      }
    },
    showAdd() {
      this.modal = true;
      this.isEdit = false;
      this.itemBasedRate = {
        additionalItemPrice: 0,
        excludeRules: [],
        firstItemPrice: 0,
        groupTag: '',
        name: '',
        priceUnit: localStorage.getItem('currencyCode') || 'USD',
        rules: [],
      };
    },
    showEdit(item) {
      this.modal = true;
      this.isEdit = true;
      this.itemBasedRate = item;
    },
    addRule() {
      if (this.itemBasedRate.rules.length < 1) {
        this.itemBasedRate.rules.push({
          column: 'product_type',
          condition: '',
          relation: 'contains',
        });
      }
    },
    addExclude() {
      if (this.itemBasedRate.excludeRules.length == 0) {
        this.itemBasedRate.excludeRules.push({
          column: 'product_type',
          condition: '',
          relation: 'contains',
        });
      }
    },
    del(index) {
      this.$parent.shippingZone.itemBasedShippingRate.splice(index, 1);
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.modal = false;
        if (!this.isEdit) {
          let isArray = Array.isArray(this.$parent.shippingZone.itemBasedShippingRate);
          if (!isArray || !this.$parent.shippingZone.itemBasedShippingRate) {
            this.$parent.shippingZone.itemBasedShippingRate = [];
          }
          this.$parent.shippingZone.itemBasedShippingRate.push(this.itemBasedRate);
        }
      }
      console.log(this.$parent.shippingZone.itemBasedShippingRate);
    },
  },
};
</script>
<style scoped>
input #my-select {
  position: absolute;
  display: inline;
  width: 0;
  height: 0;
  padding: 0;
}
</style>
