<template>
  <div>
    <div class="d-flex align-center">
      <h3>Weight based rates</h3>
      <v-btn @click="showAdd" class="ml-auto" color="primary">Add rate</v-btn>
    </div>
    <div class="mt-5">
      <p
        v-if="!$parent.shippingZone.weightBasedShippingRate || $parent.shippingZone.weightBasedShippingRate.length == 0"
      >
        Add rates based on the weight of a customer's order.
      </p>
      <v-simple-table v-else>
        <template v-slot:default>
          <thead>
            <th class="text-left" style="padding-left: 16px;">Name</th>
            <th class="text-left" style="padding-left: 16px;">Range</th>
            <th class="text-left" style="padding-left: 16px;">Rate amount</th>
            <th class="text-left" style="padding-left: 16px;"></th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in $parent.shippingZone.weightBasedShippingRate" :key="item.name">
              <td>{{ item.name }}</td>
              <td v-if="item.weightHigh == 0">{{ item.weightLow }} {{ item.weightUnit }} and up</td>
              <td v-else>{{ item.weightLow }} {{ item.weightUnit }} - {{ item.weightHigh }} {{ item.weightUnit }}</td>
              <td>
                <span v-if="parseFloat(item.price) > 0">{{ item.price }} {{ currency.currencyCode() }}</span>
                <span v-else>FREE</span>
              </td>
              <td class="text-right pr-0">
                <v-btn @click="showEdit(item)" class="btn-link">Edit</v-btn>
                <v-btn @click="del(index)" class="btn-link pr-0">Delete</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <modal title="Weight based rates" model="modal" width="750" :callback="submit">
      <template slot="content">
        <v-row>
          <v-col cols="12">
            <label for="">Name</label>
            <v-text-field
              v-model="weightBasedRate.name"
              @input="$v.weightBasedRate.name.$touch"
              :error-messages="
                $v.weightBasedRate.name.$dirty && !$v.weightBasedRate.name.required ? $t('Name is required') : ''
              "
            ></v-text-field>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <h3>RANGE</h3>
            <v-row>
              <v-col cols="12" md="6">
                <label for="">Minimum order weight</label>
                <v-text-field type="number" v-model="weightBasedRate.weightLow" prefix="Ib"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Maximum order weight</label>
                <v-text-field type="number" v-model="weightBasedRate.weightHigh" prefix="Ib"></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-0 pt-0">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <label for="">RATE</label>
                <v-checkbox
                  v-model="weightBasedRate.freeShip"
                  @change="setFree"
                  label="Free shipping rate"
                ></v-checkbox>
                <v-col v-if="!weightBasedRate.freeShip" class="py-0 px-0" cols="6">
                  <label for="">Rate amount</label>
                  <v-text-field
                    type="number"
                    v-model="weightBasedRate.price"
                    :prefix="currency.currencyCode()"
                    @input="$v.weightBasedRate.price.$touch"
                    :error-messages="
                      $v.weightBasedRate.price.$dirty && !$v.weightBasedRate.price.required
                        ? $t('Price is required')
                        : ''
                    "
                  ></v-text-field>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </modal>
  </div>
</template>
<script>
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import currency from '@/helpers/currency';

export default {
  data() {
    return {
      currency,
      modal: false,
      isEdit: false,
      weightBasedRate: {
        id: null,
        freeShip: true,
        name: '',
        weightHigh: 0,
        weightLow: 0,
        weightUnit: 'lb',
        price: 0,
        priceUnit: localStorage.getItem('currencyCode') || 'USD',
      },
    };
  },
  validations: {
    weightBasedRate: {
      name: {
        required,
      },
      price: {
        required,
      },
    },
  },
  methods: {
    showEdit(item) {
      this.isEdit = true;
      this.weightBasedRate = item;
      this.modal = true;
    },
    showAdd() {
      this.isEdit = false;
      this.weightBasedRate = {
        id: null,
        freeShip: true,
        name: '',
        weightHigh: 0,
        weightLow: 0,
        weightUnit: 'lb',
        price: 0,
        priceUnit: localStorage.getItem('currencyCode') || 'USD',
      };
      this.modal = true;
    },
    del(index) {
      this.$parent.shippingZone.weightBasedShippingRate.splice(index, 1);
    },
    setFree() {
      if (this.weightBasedRate.freeShip) {
        this.weightBasedRate.price = 0;
      }
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.modal = false;
        if (!this.isEdit) {
          let isArray = Array.isArray(this.$parent.shippingZone.weightBasedShippingRate);
          if (!this.$parent.shippingZone.weightBasedShippingRate || !isArray) {
            this.$parent.shippingZone.weightBasedShippingRate = [];
          }
          this.$parent.shippingZone.weightBasedShippingRate.push(this.weightBasedRate);
        } else {
          console.log(this.$parent.shippingZone.weightBasedShippingRate);
        }
      }
    },
  },
};
</script>
